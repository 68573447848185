
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
:root { 
  /* #0d1117 */
  --main-background: rgb(35,40,40);
  /* --main-background: #212123; */
  --second-background:rgb(60,62,70);
  /* --second-background:#3f3f3f; */
  --main-text:whitesmoke;
  --main-accent: #98FF98;
  /* --main-dark-accent.container:#5e9e5e; */
  --main-dark-accent: linear-gradient(to right, 60% #98FF98, 40% #7dd37d);
  --main-darker-accent:#69b569;
  --ff0000: #ff0000;
}
*:not(svg):not(.icon-btn){
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
  max-width: 100%;
}
::-webkit-scrollbar {
  width: 10px;
  height:10px;
}
svg:not(.dot-svg), h1{
  overflow: hidden;
}
form ::-webkit-scrollbar {
  width: 6px;
  height:10px;
}
form ::-webkit-scrollbar-track {
  background: rgba(25, 25, 25,0);
}
/* Ha
/* Track */
::-webkit-scrollbar-track {
  background: rgb(25, 25, 25);
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}
a, a span{
  font-family: Noto Sans, sans-serif;
}
.app-container{
  overflow: hidden;
  background-color: var(--main-background);
  min-height: 100.1vh;
}
.app-container.experience-container{
    /* background-color: var(--second-background); */
  background:rgba(60,62,70,1);
  background: radial-gradient(circle, rgba(60,62,70,1) 0%, rgba(30,32,40,1) 0%, rgba(60,62,70,1) 45%);
}
.app-container.projects-container{
  background-color: var(--second-background);
}
.content-container {
  color: white;
  font-size: 18px;
  width: 100%;
  padding-top: 100px;
  min-height: 100vh;
}
.section-container{
  width:100%;
  margin: 0 ;
  padding: 1px;
}
.section-content{
  margin: 0 auto;
  max-width: 1500px;
  width: calc(100% - 100px);
  position: relative;
}
.section-container-white{
  background-color: white;
  color:var(--main-background);
}

.content-container h1 {
font-size: 6rem;
letter-spacing: .02em;
font-weight: 700;
text-transform: uppercase;
margin-top:0.5rem;
}
.nav-item {
  
  animation: none;
  transition: transform 0.3s ease-in-out;
}

.nav-item.active {
  animation: pulse 1s infinite;
}


.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-right:auto;
  margin-left:40px;
}

.navbar li {
  display: inline;
  margin-right: 20px;
}

.navbar li a {
  color: transparent;
  text-decoration: none;
  padding: 10px 15px;
  transition: all 0.3s;
}
.navbar li a span{
    color: #c9d1d9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition:  all 0.3s;
}
 .navbar li a:hover span, .navbar li a.active span {
  /* background-color: #333; */
  color: white;
  font-weight: 600;
  /* margin-left:-2px */
}
.navbar li a:active span {
  color: rgb(200,200,200);
}
.navbar {
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color:  var(--main-background);
}
.navbar.second-navbar{
  background-color:  var(--second-background);
}

.navbar ul {
  list-style-type: none;
  padding: 0;
}

.navbar li {
  display: inline;
  margin-right: 20px;
}


.container {
  /* max-width: 800px; */
  margin: 0;
  padding: 0 20px;
  color: #c9d1d9;
  font-size: 18px;
  display:flex;
  align-items: center;
  justify-content:space-between;
  width:calc(100% - 40px);
}
.navbar ul {
  list-style-type: none;
  padding: 30px;
}

.navbar li {
  display: inline;
  margin-right: 20px;
  padding-bottom: 15px;
}

.navbar li a {
  color: var(--main-background);
  text-decoration: none;
  position: relative; /* To position the dotted line */
  font-size: 16px;
  transition: all 0.3s;
}


.underline {
  position: absolute;
  left: 0;
  bottom: -1px; /* Adjust based on your preference */
  width: 100%; /* Cover the entire width */
  display: none; /* Initially hidden */
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}
.nav-li{
  position: relative;
  overflow: hidden;
}

.navbar li .underline.active {
  display: block; /* Show the underline when the link is active */
  opacity: 1;
}
.logo-img{
  width:100px;
}

.underline svg{
  /* stroke-width: .3rem; */
    stroke-linecap: round;
}
.nav-contact{
  background-color: var(--main-accent);
  border:none;
  color:var(--main-background);
  padding: 20px 40px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 60px;
  min-width: 160px;
  outline: none;
  transition: all 0.3s;
  font-family: Montserrat, sans-serif;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
}
.nav-contact:hover{
  /* cursor: pointer; */
  /* background-image: var(--main-dark-accent); */
  /* color:var(--main-accent); */
}
.nav-contact:active{
  background-color: var(--main-darker-accent);
  background-image:none;
  /* color:darkgr; */
}
.nav-contact-overlay{
  background-image: linear-gradient(to right, transparent, rgba(0,0,0,0.9) );
  opacity:0;
  height: 100%;
  width:100%;
  position: absolute;
  top:0;
  left:0;
  border-radius: 60px;
  transition: opacity 0.3s;
}
.nav-contact:hover .nav-contact-overlay{
  opacity: 0.5;
}
.nav-contact:active .nav-contact-overlay{
  opacity: 0.1;
}

.split-svg{
  position: absolute;
  width: 100%;
  transition: all 0.25s;
}
.split-svg-wave-2{
  margin-top:-5px;
  transform: scaleX(1);
  transition: all 0.25s;
}
.footer-wave{
  transform: scaleX(-1);
}
.page-split-padding-light, .page-split-padding-dark{
    max-width: 100%;
    overflow: hidden;
}
.page-split-padding-light{
  aspect-ratio: 5/1;
  background-color: white;
  min-height: 200px;
}
.page-split-padding-dark{
  aspect-ratio: 5/1;
  background-color: var(--main-background);
  min-height: 200px;
}
.split-wave-2{
  aspect-ratio: 4/1;
}
.split-wave-3{
  aspect-ratio: 3/1;
  /* min-height: 300px; */
}

.main-btn{

}

.main-btn{
  background-color: transparent;
  border:1px solid white;
  color:white;
  padding: 14px 20px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 60px;
  min-width: 60px;
  outline: none;
  transition: all 0.3s;
  font-family: Montserrat, sans-serif;
  position: relative;
  overflow: hidden;
}
.main-btn.main-btn-icon{
  min-width: 20px;
  aspect-ratio: 1;
  font-size: 2.25rem;
  padding:0 0.8rem;
}
.main-btn:after{
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 3rem;
  border: 3px solid #fff;
  opacity: 0;
  transition: all .35s cubic-bezier(.3,.1,.3,1);
}
.main-btn:hover:after{
  opacity: 1;
    transition: all .35s cubic-bezier(.3,.1,.3,1);
}
.main-btn:hover{
  /* cursor: pointer; */

}
.main-btn:active{
  background-color: white;
  border-color: white !important;
  color:var(--main-background);

}
.pararaph{
  white-space: pre-wrap;
}
.main-btn:active:after{
  border-color: white !important;
}
.main-btn-overlay{
  background-image: linear-gradient(to right, transparent, rgba(0,0,0,0.9) );
  opacity:0;
  height: 100%;
  width:100%;
  position: absolute;
  top:0;
  left:0;
  border-radius: 60px;
  transition: opacity 0.3s;
}
.nav-mobile-btn{
  display: none;
}
.nav-right{
  display: inline-flex;
  align-items: center;
}
.nav-list{
  opacity:0;
  transition: opacity 2.2s;
}
.nav-list.show-nav{
  opacity:1;
}
@media (max-width: 1300px) {
  .navbar ul.nav-list{
    margin-right: 0;
    margin-left:0;
  }
  .nav-contact{
    padding: 14px 20px;
  }
}
@media (max-width: 960px) {
  
  .content-container h1{
    font-size: 3rem;
    word-break: break-all;
  }
  .container{
    width:100%;
  }
  .section-content{
    width: calc(100% - 20px);
  }
  .nav-list{
    display: none;
  }
  .nav-list.list-open{
    display: flex;
    flex-direction: column;
    background-color: var(--main-background);;
    position: absolute;
    top:75px;
    left:0;
    width: 100vw;
    height: calc(100vh - 75px);
  }
  .nav-list.gray-list{
    background-color:  var(--second-background);
  }
  .nav-mobile-btn{
    display: flex;
  }
  .BurgerButton_burger-wrapper__SnmII .BurgerButton_burger__NSfCC{
    background: none;
    border: none;
    position: relative;
    width: 3rem;
    height: 2rem;
    padding: 0;
    /* cursor: pointer; */
  }
  .nav-li{
    margin-right:0 !important;
  }
  .nav-li:not(:first-child){
    margin-top:10px;
  }
  .nav-li a, .nav-li a span{
    font-size: 25px !important;
  }
}
@media (max-width: 380px) {
  .logo-img{
    width:0;
  }
}
 /* Mobile navigation   */
 
#nav-icon3 {
  margin: 0;
  padding: 0; 
  width: 60px;
  height: 45px;
  position: relative;
  margin: 15px 15px ;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  /* cursor: pointer; */
}

#nav-icon3 span{
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
  width: calc(100% - 10px);
  left:10px;
}
#nav-icon3 span:nth-child(4) {
  width:80%;
}
#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 36px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
#SaveScreen, #SentScreen{
  display: none;
  position: fixed;
  top:0;
  left:0;
  height:100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.75);
  color:white;
  text-align: center;
  z-index: 10000;
}
#SaveScreen span, #SentScreen span{
  display: block;
  font-size:2.8rem;
  margin-top: calc(50vh - 50px);
}
#SaveScreen.show, #SentScreen.show{
  display: block;
}

@keyframes spin {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
      -webkit-transform: rotateZ(360deg);
      transform: rotateZ(360deg);
  }
}
.spinner{
  width: 40px;
  height: 40px;
  border: 2px solid #f3f3f3;
  border-top: 3px solid var(--main-accent);
  border-radius: 100%;
  margin: 15px auto;
  animation: spin 1s infinite linear;
  opacity: 1 !important;
}