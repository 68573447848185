.holo-body {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    font-size: 5em;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 800;
    height: 600px;
    margin-top:-120px;
  }
  
  .holo-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 510px;
    animation-duration: 3.5s;
    animation-name: cube-rotate;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }
  
  /* Isolation creates a new stacking context for mix-blend-mode. See: https://developer.mozilla.org/en-US/docs/Web/CSS/isolation */
  .isolate {
    isolation: isolate;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
  
  @keyframes cube-rotate {
    from {
      transform: perspective(600px) rotate3d(0.6, 0.05, 0.2, 0deg);
    }
  
    to {
      transform: perspective(600px) rotate3d(0.6, 0.05, 0.2, 12deg);
    }
  }
  
  @keyframes shimmer {
    from {
      filter: contrast(190%) brightness(500%);
    }
  
    to {
      filter: contrast(190%) brightness(130%);
    }
  }
  
  .noise {
    width: 100%;
    height: 100%;
    padding: 8px;
    background: linear-gradient(24deg, rgba(50 0 255/10%), var(--main-darker-accent)), url("data:image/svg+xml,%3Csvg viewBox='0 0 400 310' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.55' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
    background-clip: text;
    -webkit-background-clip: text;
      color: transparent;
    animation-duration: 2s;
    animation-name: shimmer;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    mix-blend-mode: multiply;
  }
  
  /* Special case Chrome. mix-blend-mode is treated differently. Works best in Safari! */
  @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
    .overlay {
      opacity: 0.6;
    }
  }
  .noise svg{
    background-clip: text;
      color:  white;
      font-size: 5rem;
    // background: linear-gradient(24deg, rgba(50 0 255/10%), var(--main-darker-accent)), url("data:image/svg+xml,%3Csvg viewBox='0 0 400 310' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.55' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  }
  @media  screen and (max-width: 930px) {
    .holo-body {
      margin-top:0px;
      height: 300px;;
    }
  }